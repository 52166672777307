<template>
  <div class="account_layout">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  computed: {
  }
}
</script>
<style lang="less">
  .submit{
    height: 78px;
    margin-top: 60px;
    button{
      height: 100%;
      font-size: 30px;
      border: 0;
      box-shadow: 0 2px 15px 0 rgba(0,115,230,0.3);
      &.van-button--disabled{
        opacity: 1;
        background: #E1E7F0;
        box-shadow: none;
      }
    }
  }
</style>
